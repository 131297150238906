// https://codepen.io/leotran/pen/XdjMzj

import { constants, instances } from '../store/index.js';

class SimpleSlides {
    constructor() {
        this.dom = {};
        this.dom.slides = document.querySelectorAll('.simple-slide');
        // this.dom.slides = document.getElementsByClassName('.simple-slide');
        
    }

    slideChange = (e) => {
        var current = 0,
        intVal = 5200,
        slides = this.dom.slides;

        setInterval(function() {
            for (var i = 0; i < slides.length; i++) {
                slides[i].style.opacity = 0;
            }
            current = (current != slides.length - 1) ? current + 1 : 0;
            slides[current].style.opacity = 1;
          }, intVal);
    }

    init() {
        this.slideChange();
    }
}

export default SimpleSlides;