// Imports
import CookieConsent from './components/cookieConsent.js';
import MobileMenu from './components/mobileMenu.js';
// import StickyHeader from './components/stickyHeader.js';
// import GwtSwiper from './components/gwt_swiper.js';
// import GwtTopbar from './components/gwt_topbar.js'; 
// import { lightbox } from './components/gwt_lightbox.js';
import StickyNavbar from './components/stickyNavbar.js';
import SimpleSlides from './components/simpleSlides.js';
import CardCollapse from './components/cardCollapse.js';
import { lightbox } from './components/glightbox.js';
// import TextRotator from './components/textRotator.js';
import { pageProgress } from './components/pageProgress.js';
import { rellax } from './components/rellax.js';
import { fscrollCue } from './components/scrollCue.js';

import { constants, instances } from './store/index.js';

// Touch Device
constants.isDevice = 'ontouchstart' in window;
constants.isDevice && document.body.classList.add('is-device');
// Mobile Device (Screen Size)
constants.isMobile = window.matchMedia("only screen and (max-width: 992px)").matches;
// console.log(constants.isMobile);
// JS Ready
document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

// console.log(instances.mainNavActive);

// set vh css var
// document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

// Edit Cookies
instances.editCookies = new CookieConsent();
instances.editCookies.init();

// Mobile Menu
instances.mobileMenu = new MobileMenu();
instances.mobileMenu.init();

instances.stickyNavbar = new StickyNavbar();
instances.stickyNavbar.init();

instances.simpleSlides = new SimpleSlides();
instances.simpleSlides.init();

instances.cardCollapse = new CardCollapse();
instances.cardCollapse.init();


pageProgress();
rellax();
fscrollCue();
lightbox();

// instances.textRotator = new TextRotator();
// instances.textRotator.init();

// instances.gwtswiper = new GwtSwiper(); 
// instances.gwtswiper.init();

// instances.gwttopbar = new GwtTopbar(); 
// instances.gwttopbar.init();

// lightbox();
