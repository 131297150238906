export const constants = {
  isDevice: false,
  isMobile: false,
  mobileMenuActive: false
};
  
export const instances = {
  // hamburger: undefined,
  editCookies: undefined,
  mobileMenu: undefined,
  stickyNavbar: undefined,
  simpleSlides: undefined,
  cardCollapse: undefined
  // textRotator: undefined
  // scroll: undefined,
  // menuMobile: undefined,
  // menudesktop: undefined,
  // gwtswiper: undefined,
  // gwttopbar: undefined
};
  