// https://medium.com/dailyjs/mimicking-bootstraps-collapse-with-vanilla-javascript-b3bb389040e7
// https://codepen.io/datchley/pen/Jvdryy

import { constants, instances } from '../store/index.js';

class CardCollapse {
    constructor() {
        this.dom = {};
        this.dom.win = window;  
        this.dom.triggers = Array.from(document.querySelectorAll('[data-toggle="cardCollapse"]'));
        // this.dom.slides = document.getElementsByClassName('.simple-slide');

        this.fnmap = {
            'toggle': 'toggle',
            'show': 'add',
            'hide': 'remove'
        };
        
    }
   
    collapse = (selector, cmd) => {
    // const collapse = (selector, cmd) => {
        const targets = Array.from(document.querySelectorAll(selector));
        targets.forEach(target => {
            target.classList[this.fnmap[cmd]]('show');
        });
    }
    addListeners() {
        this.dom.win.addEventListener('click', (ev) => {
            const elm = ev.target;
            // console.log('hello'); 
            if (this.dom.triggers.includes(elm)) {
                const selector = elm.getAttribute('data-target');
                this.collapse(selector, 'toggle');
            }
        }, false);
    }
    init() {
        if(this.dom.triggers !== undefined) {
            this.addListeners();
        }
        // if(constants.isMobile) {}
    }

   /*
   // Original COde 
   // Handler that uses various data-* attributes to trigger
    // specific actions, mimicing bootstraps attributes
    const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));

    window.addEventListener('click', (ev) => {
    const elm = ev.target;
    console.log('hello'); 
    if (triggers.includes(elm)) {
        const selector = elm.getAttribute('data-target');
        collapse(selector, 'toggle');
    }
    }, false);


    const fnmap = {
    'toggle': 'toggle',
    'show': 'add',
    'hide': 'remove'
    };
    const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach(target => {
        target.classList[fnmap[cmd]]('show');
    });
    }
    */
}

export default CardCollapse;