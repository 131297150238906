import { constants, instances } from '../store/index.js';


class MobileMenu {
    constructor() {
        this.dom = {};
        this.dom.el = document.querySelector('.btn-menu');
        this.dom.target = document.querySelector('.nav-mobile');
        // this.dom.body = document.body;
        this.dom.doc = document.documentElement; // html element
        // scroll links (onepager links)
        this.dom.scrollLinks = document.querySelectorAll('[data-scroll-links="true"] a');

        this.state = {
            active: false
        }
    }

    /*
    open = (e) => {}
    close = (e) => {}
    */
    closeMenu = (e) => {
        if(this.state.active) {
            // remove classes
            this.dom.el.classList.remove('is-active');
            this.dom.target.classList.remove('is-active');
            this.dom.doc.classList.remove('navigation-active');
            // set states
            this.dom.el.setAttribute('aria-expanded', false);
            this.dom.target.setAttribute('aria-hidden', true);
            this.state.active = false;
        }
    }

    toggle = (e) => {
        let a = this.dom.el.getAttribute('aria-expanded');
        let b = this.dom.target.getAttribute('aria-hidden');
        let current_state; 

        // toggle classes & styles 
        this.dom.el.classList.toggle('is-active');
        this.dom.target.classList.toggle('is-active');
        this.dom.doc.classList.toggle('navigation-active');
        // this.dom.body.style.overflow = 'hidden';

        if (a == 'true') {
            a = 'false';
            b = 'true';
            current_state = false; 
            // this.state.open = false;
        } else {
            a = 'true';
            b = 'false';
            current_state = true; 
            // this.state.open = true;
        }
        // console.log(this.state.open); 
        this.dom.el.setAttribute('aria-expanded', a);
        this.dom.target.setAttribute('aria-hidden', b);
        this.state.active = current_state; 
        constants.mobileMenuActive = current_state;
    }

    addListeners() {
        this.dom.el.addEventListener('click', this.toggle);

        for (var i = 0; i < this.dom.scrollLinks.length; i++) { 
            this.dom.scrollLinks[i].addEventListener('click', this.closeMenu);
        }
    }

    init() {
        // console.log(this.state.active); 
        this.addListeners();
    }
}


export default MobileMenu;