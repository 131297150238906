// Read: http://jsfiddle.net/moogs/869fqgds/4/

import { constants, instances } from '../store/index.js';

class StickyNavbar {
    constructor() {
        this.dom = {};
        this.dom.win = window;  
        this.dom.doc = document;  
        this.dom.body = document.body;
        this.dom.el = document.querySelector('.header-nav-wrap');
        
        /*
        this.state = {
            scrollUp: 'scroll-up',
            scrollDown: 'scroll-down',
            lastScroll: 0
        }
        */
    }

    scroll = (event) => {
        
        let top = this.dom.el.offsetTop;
        // let y = this.dom.doc['documentElement' || 'body'].scrollTop;
        const y = this.dom.win.pageYOffset;
        if (y >= top) this.dom.el.classList.add('is-sticky');
        else this.dom.el.classList.remove('is-sticky');
        // console.log('fired', top, y); 

        /*
        const currentScroll = this.dom.win.pageYOffset;
        if (currentScroll <= 0) {
            this.dom.body.classList.remove(this.state.scrollUp);
            return;
        }
        if (currentScroll > this.state.lastScroll && !this.dom.body.classList.contains(this.state.scrollDown)) {
            // down
            this.dom.body.classList.remove(this.state.scrollUp);
            this.dom.body.classList.add(this.state.scrollDown);
            // lottiePlayer.play();
        } else if (
            currentScroll < this.state.lastScroll &&
            this.dom.body.classList.contains(this.state.scrollDown)
        ) {
            // up
            this.dom.body.classList.remove(this.state.scrollDown);
            this.dom.body.classList.add(this.state.scrollUp);
            // lottiePlayer.stop();
        }
        this.state.lastScroll = currentScroll;
        */
    }

    addListeners() {
        // this.dom.win.addEventListener('scroll', this.scroll);  
        // this.dom.win.addEventListener('DOMContentLoaded', this.scroll);
        this.dom.win.addEventListener('scroll', this.scroll);
    }

    init() {
        if(!constants.isMobile) {
            if (this.dom.doc.readyState == 'complete' || this.dom.doc.readyState == 'loaded') {
                this.scroll();
            } else {
                this.addListeners();
            }
            
        }
    }
}

export default StickyNavbar;

