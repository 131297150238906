import GLightbox from 'glightbox';

/*
const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
});

// export { lightbox };

// export default lightbox;
module.exports = lightbox;
*/


var ligthboxEl = document.querySelectorAll('.lightbox');
// check if typeElemen exists
var checkEl = (typeof(ligthboxEl) != 'undefined' && ligthboxEl != null) ? true : false;	

function init() {
    console.log('Lightbox init running');
	// set up lighbox 
	const lightbox = GLightbox({
		selector: '.lightbox',
    	touchNavigation: true,
        loop: true,
        autoplayVideos: true
	});
}

// export function 
const lightbox = function() {	 
    // wait until DOM is ready
     document.addEventListener('DOMContentLoaded', function(event) {
        if(checkEl) {
        	init();
        }
    });
};

export { lightbox };